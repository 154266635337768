import React, { useEffect, useState, useRef } from "react";

// Mui Components
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";

// Child Component
import Navbar from "../../Components/Common/Navbar";
import Newsletter from "../../Components/Common/Newsletter";
import Footer from "../../Components/Common/Footer";
import sellImg from "../../assets/sell_1.png";

import icon1 from "../../assets/Icons/sell/1.svg";
import icon2 from "../../assets/Icons/sell/2.svg";
import StoreInformation from "../../Components/Sell/StoreInformation";
import icon3 from "../../assets/Icons/sell/3.svg";
import icon4 from "../../assets/Icons/sell/4.svg";
import icon5 from "../../assets/Icons/sell/5.svg";
import Identity from "../../Components/Sell/Identity";
import Modal from "@mui/material/Modal";
import ConfettiExplosion from "react-confetti-explosion";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_PUSRSES, GET_SELL_AMOUNT } from "../../Utils/Queries";
import { PAY_VERIFICATION, WAIVE_PAYMENT } from "../../Utils/Mutations";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../store/userSlice";
import { useSnackbar } from "notistack";
import Loader from "../../Components/Common/Loader";
import AddCash from "../../Account-Profile/Components/Mamapurse/AddCash";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

// Assets

const Download = () => {
  const [open, setOpen] = React.useState(false);

  const location = useLocation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [isExploding, setIsExploding] = React.useState(false);

  const [activeStep, setActiveStep] = useState(1);

  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  const isSeller = useSelector(
    (state) => state.user?.user?.profile?.hasPaidMembershipFee
  );

   const addCashRef = useRef(null);

  // useEffect(() => {
  //   if (isSeller && isAuthenticated) {
  //     navigate("/sell-on-mamaket");
  //   }
  // }, [isSeller, isAuthenticated]);

  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const {
    data: wallets,
    loading: walletLoading,
    error: walletError,
  } = useQuery(GET_ALL_PUSRSES);
  const { data, loading, error } = useQuery(GET_SELL_AMOUNT);

  const spendingBalance = wallets?.getWallets[0]?.spendingBalance;
  const amountToPay = data?.getSellerBills?.amountToPay;
  const handleOpen = () => {
    setIsExploding(true);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsExploding(false);
  };

  const [payVerification] = useMutation(PAY_VERIFICATION);
  const [waiveVerification] = useMutation(WAIVE_PAYMENT);

  const [isLoading, setIsLoading] = useState(false);


  const handlePayVerification = async () => {
    setIsLoading(true);
    await payVerification()
      .then((res) => {
        setIsLoading(false);
        handleOpen();
        dispatch(logoutUser());
      })
      .catch((err) => {
            setIsLoading(false);
if(err.message === "Insufficient balance. Kindly top-up to proceed"){
    addCashRef.current?.click();
}else{
     handleAlert(`${err.message}`, "error");
}
    
   
      });
  };
  const handleWaiveVerification = async () => {
    setIsLoading(true);
    await waiveVerification()
      .then((res) => {
        setIsLoading(false);
        handleOpen();
        dispatch(logoutUser());
      })
      .catch((err) => {

        setIsLoading(false);
        handleAlert(`${err.message}`, "error");
      });
  };



  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <Box sx={{ pt: { lg: 5, md: 5, sm: 5, xs: 2 } }}>
        <Box
          sx={{
            margin: "0 auto",
            width: { lg: "50%", md: "60%", sm: "90%", xs: "95%" },
            mt: 7,
            pb: 7,
            bgcolor: "#fff",
            border: "1px solid #e4e4e7",
            px: { lg: 4, md: 4, sm: 2, xs: 2 },
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              mt: 5,
              display: "flex",
              columnGap: 2,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {[1, 2]?.map((index) => (
              <Box
                onClick={() => activeStep > index && setActiveStep(index)}
                sx={{
                  bgcolor: "#F5EEF7",
                  height: "8px",
                  borderRadius: "50px",
                  width: "117px",
                  cursor: "pointer",
                  boxSizing: "border-box",
                }}
              >
                <Box
                  sx={{
                    height: "8px",
                    borderRadius: "50px",
                    boxSizing: "border-box",
                    width: "0%",
                    transition: "0.2s all linear",
                    ...(activeStep >= index && {
                      bgcolor: "#7B408C",
                      width: "100%",
                    }),
                  }}
                />
              </Box>
            ))}
            <Typography sx={{ fontWeight: 700, color: "#7B408C" }}>
              {activeStep}/2
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            {activeStep === 1 && (
              <>
                <Box>
                  <Typography
                    sx={{ fontWeight: 500, fontSize: "", color: "#151515" }}
                  >
                    Selling on Mamaket
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Let’s get started. Tell us about your business
                  </Typography>
                  <Box
                    sx={{
                      height: "200px",
                      mt: 1,
                      background: `url('${sellImg}')`,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      bgcolor: "#8a8a8a",
                      pl: 3,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: "20px",
                        color: "#fff",
                        pt: 11,
                      }}
                    >
                      Earn money by <br />
                      selling on Mamaket
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: { lg: "70%", md: "70%", sm: "90%", xs: "100%" },
                      margin: "0 auto",
                      mt: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <img src={icon1} />
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            color: "#151515",
                            fontSize: "",
                          }}
                        >
                          Get verified
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            lineHeight: "16px",
                            color: "#4d4d4d",
                          }}
                        >
                          Verify your identity in seconds with our secure and
                          reliable ID verification service.
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                        mt: 2,
                      }}
                    >
                      <img src={icon2} />
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            color: "#151515",
                            fontSize: "",
                          }}
                        >
                          List items or services
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            lineHeight: "16px",
                            color: "#4d4d4d",
                          }}
                        >
                          Easily add your products/services online and reach a
                          wider audience with just a few clicks.
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                        mt: 2,
                      }}
                    >
                      <img src={icon3} />
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            color: "#151515",
                            fontSize: "",
                          }}
                        >
                          Get Paid
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            lineHeight: "16px",
                            color: "#4d4d4d",
                          }}
                        >
                          Get paid effortlessly! Complete a simple post-sale
                          step and receive payment directly to your bank
                          account.
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ width: "80%", margin: "0 auto", mt: 5, mb: 5 }}>
                      <Button
                        onClick={() =>
                          isAuthenticated
                            ? setActiveStep(activeStep + 1)
                            : navigate("/login", {
                                state: { redirectTo: location.pathname },
                              })
                        }
                        fullWidth
                        variant="contained"
                        sx={{
                          borderRadius: "50px",
                          fontWeight: 400,
                          height: "52px",
                        }}
                      >
                        Get Started
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            {activeStep === 4 && (
              <>
                <Box>
                  <Typography
                    sx={{ fontWeight: 500, fontSize: "", color: "#151515" }}
                  >
                    Store Information
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Let’s get started. Tell about your business
                  </Typography>

                  <Box
                    sx={{
                      width: "80%",
                      margin: "0 auto",
                      mt: 3,
                      bgcolor: "#f7f7f7",
                      p: 3,
                      boxSizing: "border-box",
                      borderRadius: "10px",
                    }}
                  >
                    <StoreInformation />
                  </Box>

                  <Box sx={{ width: "60%", margin: "0 auto", my: 6 }}>
                    <Button
                      onClick={() => setActiveStep(activeStep + 1)}
                      variant="contained"
                      fullWidth
                      sx={{
                        borderRadius: "50px",
                        fontWeight: 400,
                        height: "52px",
                      }}
                    >
                      Continue
                    </Button>
                  </Box>
                </Box>
              </>
            )}
            {activeStep === 3 && (
              <>
                <Box>
                  <Typography
                    sx={{ fontWeight: 500, fontSize: "", color: "#151515" }}
                  >
                    ID Verification
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Submit your ID for verification of identity
                  </Typography>
                  <Box
                    sx={{
                      width: "80%",
                      margin: "0 auto",
                      mt: 3,
                      boxSizing: "border-box",
                      borderRadius: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#151515",
                        fontWeight: 700,
                        fontSize: "20px",
                      }}
                    >
                      Let's verify your identity in 3 minutes
                    </Typography>
                    <Typography sx={{ fontSize: "12px" }}>
                      We need to verify your identity, so you gain access to
                      list products and services.
                    </Typography>
                    <Box
                      sx={{
                        mt: 2,
                        p: 3,
                        boxSizing: "border-box",
                        bgcolor: "#f7f7f7",
                        borderRadius: "10px",
                      }}
                    >
                      <Identity />
                    </Box>
                    <Box sx={{ width: "80%", margin: "0 auto", mt: 5, mb: 5 }}>
                      <Button
                        onClick={() => setActiveStep(activeStep + 1)}
                        fullWidth
                        variant="contained"
                        sx={{
                          borderRadius: "50px",
                          fontWeight: 400,
                          height: "52px",
                        }}
                      >
                        Proceed
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            {activeStep === 2 && (
              <>
                <Box>
                  <Typography
                    sx={{ fontWeight: 500, fontSize: "", color: "#151515" }}
                  >
                    Subscription Fee
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Pay the store one time fee
                  </Typography>

                  <Box
                    sx={{
                      width: { lg: "70%", md: "70%", sm: "90%", xs: "100%" },
                      margin: "0 auto",
                      mt: 3,
                      bgcolor: "#FEF7FF",
                      p: 3,
                      boxSizing: "border-box",
                      borderRadius: "10px",
                      border: "1px solid #EBDCEF",
                    }}
                  >
                    <Typography sx={{ color: "#151515", fontWeight: 600 }}>
                      One time fee
                    </Typography>
                    {loading ? (
                      <CircularProgress size={20} sx={{ mt: 1 }} />
                    ) : (
                      <Typography
                        sx={{
                          color: "#151515",
                          fontWeight: 900,
                          fontSize: "35px",
                          mt: 1,
                        }}
                      >
                        $
                        {amountToPay
                          ? parseFloat(amountToPay).toLocaleString()
                          : "--"}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      width: "80%",
                      margin: "0 auto",
                      mt: 2,
                    }}
                  >
                    <Typography sx={{ fontWeight: 500, color: "#151515" }}>
                      Pay with
                    </Typography>
                    <Box
                      sx={{
                        mt: 1,
                        bgcolor: "#8a8a8a1a",
                        p: 2,
                        boxSizing: "border-box",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,

                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                        }}
                      >
                        <img src={icon4} />
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 700,
                              color: "#151515",
                            }}
                          >
                            Mamapurse
                          </Typography>
                          {walletLoading ? (
                            <CircularProgress size={20} sx={{ mt: 1 }} />
                          ) : (
                            <Typography
                              sx={{
                                fontSize: "20px",
                                fontWeight: 600,
                                color: "#151515",
                              }}
                            >
                              $
                              {spendingBalance
                                ? parseFloat(spendingBalance).toLocaleString()
                                : " 0.00"}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    
                        <AddCash text="Top up"  ref={addCashRef} />
                 
                    </Box>
                  </Box>
                  <Box sx={{ width: "50%", margin: "0 auto", mt: 5, mb: 5 }}>
                    <Button
                      onClick={() => handlePayVerification()}
                      fullWidth
                      variant="contained"
                      sx={{
                        borderRadius: "50px",
                        fontWeight: 400,
                        height: "52px",
                      }}
                    >
                      Pay Now
                    </Button>
                              <Button
                      onClick={() => handleWaiveVerification()}
                      fullWidth
                      variant="standard"
                      sx={{
                        borderRadius: "50px",
                        fontWeight: 400,
                        height: "52px",
                        mt:3,
                        textDecoration:'underline'
                      }}
                    >
              Waive payment
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Newsletter />
      <Footer />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ConfettiExplosion
              duration={6000}
              zIndex={10000}
              particleSize={7}
              colors={["#EBDCEF", "#7B408C", "#1F1023", "#FFCD29", "#FFF9E5"]}
            />
            <img src={icon5} width={80} />
            <Typography
              sx={{
                mt: 2,
                fontWeight: 700,
                color: "#151515",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              Your store is open for business!
            </Typography>
            <Typography
              sx={{
                mt: 1,
                textAlign: "center",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 400,
              }}
            >
              Congratulations! You will beupdated when you are verified to
              easily list/add your products and services with just a few clicks
            </Typography>
            <Typography
              sx={{
                mt: 1,
                textAlign: "center",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 400,
              }}
            >
              Please login again to have full access to sell on Mamaket
            </Typography>

            <Button
              onClick={() => {
                navigate("/login", {
                  state: { redirectTo: location.pathname },
                });
                handleClose();
              }}
              variant="contained"
              sx={{
                width: "150px",
                height: "50px",
                borderRadius: "50px",
                mt: 3,
              }}
            >
              Proceed
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Download;
