import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import { CloseOutlined } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import cardAdded from "../../../assets/Icons/cardAdded.svg";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  Modal,
  Button,
  Typography,
  Box,
  TextField,
  IconButton,
  
} from "@mui/material";

import { ADD_FUNDS } from "../../../Utils/Mutations";
import { useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Cashout from "./Cashout";
import CardInput from "./CardInput";
import { useLocation } from "react-router-dom";

const p_Key =
process.env.REACT_APP_STRIPE_KEY
const stripePromise = loadStripe(p_Key);
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 350, md: 350, sm: 250, xs: 280 },
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  overflowY:'scroll',
  maxHeight:'80vh'
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 300, md: 300, sm: 250, xs: 250 },
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const AddCash = forwardRef (({ text, variant, color, bgcolor }, ref)=> {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const location = useLocation()

  const [isInitialized, setIsInitialized] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);



  const handleAmountChange = (e) => {
    // Use parseFloat to convert the input value to a float
    const newValue = parseFloat(e.target.value);

    // Check if newValue is a valid number (not NaN)
    if (!isNaN(newValue)) {
      setAmount(newValue);
    } else {
      setAmount(null);
      // Handle invalid input (e.g., display an error message)
    }
  };

  const [initializeWallet, { data, loading }] = useMutation(ADD_FUNDS, {
    onCompleted: (queryData) => {

      let data = queryData.initializeWalletFunding;
      setClientSecret(data.client_secret);
      localStorage.setItem("amount", amount);
      setIsInitialized(true);
    },
  });

  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#2E1834",
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  const handleInitializeWallet = () => {
    setIsInitialized(false);
    initializeWallet({
      variables: {
        amount,
      },
    });
  };
  const formattedAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD", // Replace with your desired currency code
    minimumFractionDigits: 2, // Number of decimal places
  }).format(amount);

    const handleAddReroute = () => {
    localStorage.setItem("mamapurse_reroute", location.pathname);
    handleOpen()
  };

  const buttonRef = useRef(null);

   useImperativeHandle(ref, () => ({
    click: () => {
      buttonRef.current?.click();
    },
  }));

  return (
    <>
      <Button
         ref={buttonRef}
        variant={variant ? variant : "contained"}
        onClick={handleAddReroute}
        sx={{
          background: bgcolor ? bgcolor : "#fff",
          color: color ? color : "#2E1834",
          borderRadius: "40px",
          fontSize: "13px",
          "&:hover": {
            background: "#ffffffb7",
            color: "#2E1834",
          },
        }}
      >
        {text} <AddOutlinedIcon sx={{ fontSize: "13px" }} />
      </Button>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}  >
          {isInitialized ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ color: "#333", fontSize: "20px", fontWeight: 500 }}
                >
                  Add Money
                </Typography>
                <IconButton onClick={() => setIsInitialized(false)}>
                  <KeyboardBackspaceIcon sx={{ color: "#333" }} />
                </IconButton>
              </Box>
              <Box sx={{ mt: 3 }}>
                {clientSecret && (
                  <Elements options={options} stripe={stripePromise}>
                    <CardInput amount={amount} />
                  </Elements>
                )}
              </Box>
              <Box sx={{ mt: 3 }}>
                {/* <Button onClick={handleSubmit} variant="contained" fullWidth>{ `Pay ${formattedAmount}`}</Button> */}
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ color: "#333", fontSize: "20px", fontWeight: 500 }}
                >
                  Add Money
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseOutlined sx={{ color: "#333" }} />
                </IconButton>
              </Box>
              <Box sx={{ mt: 3 }}>
                <TextField
                  fullWidth
                  helperText={formattedAmount}
                  placeholder="Amount"
                  value={amount}
                  type="number"
                  onChange={handleAmountChange}
                  InputProps={{
                       inputMode:'numeric', pattern:'[0-9]*',
                    style: {
            

                      fontSize: "13px",
                      borderRadius: "8px",
                      offset: " 1px solid #f2f2f2",
                      // Replace with your desired font family
                    },
                  }}
                />

                {loading && (
                  <LoadingButton
                    fullWidth
                    loading
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled
                    sx={{ borderRadius: "20px", py: 1, mt: 3 }}
                  >
                    making Payment
                  </LoadingButton>
                )}
                {!loading && (
                  <Button
                    fullWidth
                    onClick={handleInitializeWallet}
                    variant="contained"
                    disabled={!amount}
                    sx={{
                      color: "#fff",
                      mt: 3,
                      borderRadius: "100px",
                    }}
                  >
                    Pay Now
                  </Button>
                )}
              </Box>
            </>
          )}{" "}
        </Box>
      </Modal>
    </>
  );
});

export default AddCash;
