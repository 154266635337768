import {  Box, IconButton, InputLabel, Typography, Rating } from "@mui/material";
import React from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import img1 from '../../assets/Products/Web Images.png'
import usFlag from '../../assets/Flag-United-States-of-America.webp'
import noImage from '../../assets/no-image.png'
import CurrencyDisplay from "../Common/CurrencyDisplay";

const ProductsCard = ({ image, title, price, ratings,description }) => {


  return (
    <>
      <Box
        sx={{
          border: "1px solid #F8F7F7",
          bgcolor: "#fff",
          borderRadius: "8px",
          cursor: "pointer",
          transition: "0.3s all linear",
          p: 1,
          "&:hover": {
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          },
          width:{md:'auto', sm:'auto', xs:'auto'}
        }}
      >
        <Box>
          {/* <Box sx={{display:'grid', placeItems:'center', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'}}> */}
          <Box
            
        
            sx={{ width: "100%", height: 
            {xs:"120px", sm:'120px', md:'150px', 
            lg:'150px'} , 
          backgroundImage:`url('${image ? image : noImage}')`,
          backgroundSize:'cover',
          backgroundRepeat:'no-repeat',
          backgroundPosition:'center',
borderRadius:'5px'
          }}
          />
          {/* </Box> */}


          <Box sx={{ mt: 2, pb: 1, height:'80px' }}>
            <InputLabel
              variant="caption"
              sx={{ fontSize: "12px", fontWeight: 500, color:'#1a1a1a' }}
            >
              {title}
            </InputLabel>
            <Typography
              variant="caption"
              className="two-line-ellipsis"
              sx={{ fontSize: "8px", fontWeight: 300, color:'#1a1a1a' , height:'24px'}}
            >
              {description}
            </Typography>

            <Typography
           
              sx={{ color: "#333", fontSize: "12px", fontWeight: 400 ,mt:1}}
            >
                <span style={{fontSize:'10px',  marginRight:'1px'}}>&#36;</span>{price.toLocaleString()}
            </Typography>
            {/* <CurrencyDisplay amount={price.toLocaleString()}/> */}
               <Rating
                     
                           value={parseFloat(ratings)}
                           readOnly
                           size="small"
                           sx={{fontSize:'12px', color:'#df7922'}}
                           precision={0.5}
                         />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProductsCard;
