import React from "react";
import Navbar from "../Common/Navbar";
import FullSearchBar from "../Search/FullSearchBar";
import { Box, Typography, Popover, Grid } from "@mui/material";
import Newsletter from "../Common/Newsletter";
import Footer from "../Common/Footer";
import { VscSettings } from "react-icons/vsc";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_PRODUCT_ORIGIN_ID } from "../../Utils/Queries";
import { Link } from "react-router-dom";
import ProductsCard from "../Cards/ProductsCard";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const Overview = () => {
  const { name, id:country_id } = useParams();



   const [anchorEl, setAnchorEl] = React.useState(null);

   const {data, loading, error} = useQuery(GET_PRODUCT_ORIGIN_ID, {variables:{
    countryId:country_id
   }})

   console.log(data,error, "DAmi")

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  const products = data?.
fetchProductsByCountry?.items || []
  return (
    <>
      <Navbar />
      <Box sx={{ mt: { lg: 13, md: 12, sm: 8, xs: 8 } }}>
        <FullSearchBar />
  
      </Box>
      <Box
        sx={{
          margin: "0 auto",
          width: { xl: "75%", lg: "85%", md: "87%", sm: "100%", xs: "95%" },
          mt: 3,
        }}
      >
                   <Box
        sx={{
          p: 1,
          px: 2,
          bgcolor: "#fff",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Link to="/" style={{ fontWeight: 400, color: "#666" }}>
            <Typography
              sx={{
                color: "#666",
                fontWeight: 500,

                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Home
            </Typography>
          </Link>
          <NavigateNextIcon />
          <Link to="/">
            <Typography
              sx={{
                color: "#666",
                fontWeight: 500,

                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Products Origins
            </Typography>
          </Link>
          <NavigateNextIcon />
          <Typography
            sx={{
              color: "#333",
              fontWeight: 500,

              fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
            }}
          >
      {name}
          </Typography>
        </Box>
      </Box>
        <Box sx={{ height: "100px", display: "grid", placeContent: "center" }}>
          <Typography sx={{ color: "#333", fontSize: "20px" }}>
            Search results for <b>{name}</b>
          </Typography>
        </Box>
        <Box sx={{ bgcolor: "#fff", mb:5 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
              pt: 3,
            }}
          >
            <Typography sx={{fontSize:'14px'}}>{products?.length || 0} products</Typography>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 , cursor:'pointer'}}  aria-describedby={id}  onClick={handleClick}>
              <Typography sx={{ fontSize: "14px", color: "#333" , }}>
                Filter
              </Typography>
              <VscSettings />
            </Box>


          </Box>

          <Box sx={{p:{md:4, xs:2}}}>
            <Grid container spacing={2}>
              {
                products?.map((item, index)=>(
                    <Grid item lg={2.4} md={3} sm={4} xs={6} key={item._id}>
                      <Link to={`/product-view/${item._id}`} key={index}>
                        <ProductsCard
                        title={item?.name}
                          image={item.images && item.images[0]}
                          price={item.price.toLocaleString()}
                          flag={item?.country?.flagUrl}
                          description={item?.description}
                          ratings={item?.rating}
                        />
                      </Link>
                    </Grid>
                ))
              }
            </Grid>
          </Box>
        </Box>
      </Box>
      <Newsletter />
      <Footer />


      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
      </Popover>
    </>
  );
};

export default Overview;
