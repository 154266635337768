import { Box, Typography, Grid, useMediaQuery,useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ServiceCard from '../Cards/ServiceCard'
import { Link } from 'react-router-dom'
import { getServices } from '../../store/serviceSlice'
import StatusCode from '../../Utils/StatusCode'
import Loader from '../Common/Loader'



const ServiceListings = () => {
  const dispatch = useDispatch()

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  
  useEffect(() => {
    dispatch(getServices())
  }, [])
      const { data, status } = useSelector((state) => state.service);
  return (
    <>
      {status === StatusCode.LOADING && <Loader />}
      <Box sx={{           boxShadow:' rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px', borderRadius:'5px', p: 2, pb: 3, my: 5, bgcolor:' #f6f7f8',    }}>
        <Typography variant="subtitle2" sx={{     fontSize: {
              lg: "24px",
              xs: "16px",
              md: "24px",
              sm: "18px",
      
            }, fontWeight:700}}>Our Service Listings</Typography>

        <Grid container spacing={2} sx={{ my: 1 }}>
          {data === undefined ? (
            <p>No Data</p>
          ) : (
            data?.slice(0, isMobile ?  6 : 10).map((service, index) => (
              <Grid item lg={2.4} md={3} sm={4} xs={6} key={index}>
                <Link to={`/service-view/${service._id}`}>
                  <ServiceCard
                    image={service.images}
                    title={service.title}
                    price={service.price.toLocaleString()}
                    description={service.description}
                  />
                </Link>
              </Grid>
            ))
          )}
        </Grid>
      </Box>
    </>
  );
}

export default ServiceListings
