import React, { useState, useEffect } from 'react';
import img1 from '../../assets/banner/1.webp'
import img2 from '../../assets/banner/2.webp'
import img3 from '../../assets/banner/3.webp'

import { Box, Typography } from '@mui/material';


const images = [
 img1, img2, img3, 
]
const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 6000);

    return () => clearInterval(interval);
  }, [images.length, 6000]);

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="carousel">
      <div className="carousel-images">
        {images.map((imageUrl, index) => (
          <img
            key={index}
            src={imageUrl}
            alt={`Slide ${index}`}
            className={`carousel-image ${index === activeIndex ? 'active' : ''}`}
          />
        ))}

    
      </div>

      <div className="carousel-dots">
        {images.map((_, index) => (
          <div
            key={index}
            className={`carousel-dot ${index === activeIndex ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>

      {/* <Box sx={{display:{lg:'block', md:'block', sm:'none', xs:'none'}}} className="carousel-caption">
   <Typography variant='h6' sx={{    fontFamily: 'Space Grotesk'}}>Mamaket <br/> Buy, Sell, Revere your Culture!</Typography>
      </Box> */}
    </div>
  );
};

export default Carousel;

