import { useState } from "react";
import { favItemsVar } from "../Utils/cache";
import { useLazyQuery, useReactiveVar,  } from "@apollo/client";
import {  GET_FAVOURITES } from "../Utils/Queries";


const useGetFav = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const favItems = useReactiveVar(favItemsVar);

  const [getFav, { data, refetch }] = useLazyQuery(GET_FAVOURITES);


  const handleGetFav = async () => {
        
    setIsLoading(true);

    await getFav({
                        
    pollInterval: 2000,
  
    })
      .then((res) => {

        setIsLoading(false);
        setSuccess(true);
        favItemsVar(res?.data?.getMyFavourites?.items);
      })
      .catch((err) => {
        console.error("Error Newe Cart", err);
        setIsLoading(false);
      });
  };

  return { handleGetFav, isLoading, isSuccess, refetch};
};

export default useGetFav;
