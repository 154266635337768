import { useState } from "react";
import { cartItemsVar } from "../Utils/cache";
import { useLazyQuery, useReactiveVar,  } from "@apollo/client";
import { GET_CART } from "../Utils/Queries";


const useGetCart = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const cartItems = useReactiveVar(cartItemsVar);

  const [getCart, { data, refetch }] = useLazyQuery(GET_CART);


  const handleGetCart = async () => {
        
    setIsLoading(true);

    await getCart({
                        
    pollInterval: 2000,
  
    })
      .then((res) => {

        setIsLoading(false);
        setSuccess(true);
        cartItemsVar(res?.data?.getMyShoppingCart?.items);
      })
      .catch((err) => {
        console.error("Error Newe Cart", err);
        setIsLoading(false);
      });
  };

  return { handleGetCart, isLoading, isSuccess, refetch};
};

export default useGetCart;
