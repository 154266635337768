import {
  Box,
  Button,
  IconButton,
  Typography,
  Drawer,
  Skeleton,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { GET_CATEGORIES_N_SUB } from "../../Utils/Queries";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { HashLink } from "react-router-hash-link";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CategoryMenu = () => {
  const isAuthenticated = useSelector((state) => state.user?.isAuthenticated);
  const userInfo = useSelector((state) => state.user?.user?.profile);
  const [openDrawer, setOpenDrawer] = useState(false);
// const loading = true;
  const { loading, data, error } = useQuery(GET_CATEGORIES_N_SUB);

    const isSeller = useSelector((state) => state.user?.user?.profile?.hasPaidMembershipFee);

  const [collapse, setCollapse] = useState(false)
  return (
    <>
      <Box>
        <IconButton
          onClick={() => setOpenDrawer(true)}
          sx={{ borderRadius: "0px", ml: -1 }}
        >
          <MenuIcon sx={{ color: "#1a1a1a" }} />
          <Typography
            variant="caption"
            sx={{ color: "#1a1a1a", ml: 0.5, fontWeight: 500 }}
          >
            All
          </Typography>
        </IconButton>
      </Box>

      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={{ width: "30vw"  }}
      >
        <Box sx={{ p: 2, width: "30vw" , bgcolor:'#5C3069', height:'100%'}}>
          <Box
            sx={{
              bgcolor: "#fff",
              p: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {isAuthenticated ? (
              <Typography
                sx={{ color: "#000" }}
              >{`Hello, ${userInfo?.firstName}`}</Typography>
            ) : (
              <Typography sx={{ color: "#000" }}>Hello, Sign In</Typography>
            )}
            <IconButton
              sx={{ color: "#fff" }}
              onClick={() => setOpenDrawer(false)}
            >
              <CloseOutlined />
            </IconButton>
          </Box>

          <Box
            sx={{
              mt: 3,
              height: "87vh",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: "0", // Set the width of the scrollbar to zero
              },
            }}
          >
            <Box sx={{ mb: 2 }}>
              <Box
                onClick={() => setCollapse(!collapse)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontWeight: 700, color: "#fff" }}>
                  Categories
                </Typography>
                <IconButton onClick={() => setCollapse(!collapse)} sx={{color:'#fff'}}>
                  {collapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>
              <Box sx={{ py: 1, display: collapse ? "block" : "none" }}>
                {loading ? (
                  <>
                    <ListLoader />
                  </>
                ) : (
                  <>
                    {data?.fetchAllCategoriesAndTheirSubCategories.map(
                      (item, index) => (
                        <Link
                               key={index}
                          to={`/category-view/${item.category.name}/${item.category._id}`}
                        >
                          <ListItemButton
                     
                            sx={{
                              p: 0,
                              px: 1,
                              py: 1,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              mb: 0,
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#fff",
                                fontWeight: 400,
                                fontSize: "14px",
                              }}
                            >
                              {item?.category?.name}
                            </Typography>

                            <IconButton>
                              {/* <ChevronRightIcon /> */}
                            </IconButton>
                          </ListItemButton>
                        </Link>
                      )
                    )}
                  </>
                )}
              </Box>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography sx={{ fontWeight: 700, color: "#fff" }}>
                Deals
              </Typography>
              <Box sx={{ p: 1 }}>
                <HashLink
                  to="/#latestProducts"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  onClick={() => setOpenDrawer(false)}
                >
                  <Typography
                    sx={{ fontSize: "14px", color: "#fff", fontWeight: 400 }}
                  >
                    Latest
                  </Typography>
                </HashLink>
                <HashLink
                  to="/#topProducts"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  onClick={() => setOpenDrawer(false)}
                >
                  <Typography
                    sx={{ fontSize: "14px", color: "#fff", fontWeight: 400 , mt:1}}
                  >
                    Top Sellers
                  </Typography>
                </HashLink>
              </Box>
            </Box>
            <Box sx={{my:2}}>
              {
                isSeller ? (
                  <Link to="/sell-on-mamaket">
   <Button
   fullWidth
                  variant="contained"
                  sx={{
                     color:'#1A1A1A',
                    background: "#fff",
                    "&:hover": { background: "#f4f4f4" },
             
                  }}
                >
                  Sell on Mamaket
                </Button>
                  </Link>
                ):(
     <Link to="/download">
                <Button
                fullWidth
                  variant="contained"
                  sx={{
                    color:'#1A1A1A',
                    background: "#fff",
                    "&:hover": { background: "#f4f4f4" },
                  }}
                >
                  Become A Seller
                </Button>
              </Link>
                )
              }
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography sx={{ fontWeight: 700, color: "#fff" }}>
                Account
              </Typography>
              <Box sx={{ p: 1 }}>
                <Link to="/chat" onClick={() => setOpenDrawer(false)}>
                  <Typography
                    sx={{ fontSize: "14px", color: "#fff", fontWeight: 400 }}
                  >
                    Chat
                  </Typography>
                </Link>
            
                <Link
                  to="/account/mamapurse"
                  onClick={() => setOpenDrawer(false)}
                >
                  <Typography
                    sx={{ fontSize: "14px", color: "#fff", fontWeight: 400, mt:2}}
                  >
                    Mamapurse
                  </Typography>
                </Link>
                <Link to="/account/orders" onClick={() => setOpenDrawer(false)}>
                  <Typography
                    sx={{ fontSize: "14px", color: "#fff", fontWeight: 400, mt:2}}
                  >
                    My Orders
                  </Typography>
                </Link>
                <Link to="/account" onClick={() => setOpenDrawer(false)}>
                  <Typography
                    sx={{ fontSize: "14px", color: "#fff", fontWeight: 400, mt:2}}
                  >
                    My Profile
                  </Typography>
                </Link>
              </Box>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography sx={{ fontWeight: 700, color: "#fff" }}>
                Mamaket
              </Typography>
              <Box sx={{ p: 1 }}>
                <Link to="/about-us" onClick={() => setOpenDrawer(false)}>
                  <Typography
                    sx={{ fontSize: "14px", color: "#fff", fontWeight: 400, mt:2}}
                  >
                    About Us
                  </Typography>
                </Link>
                <HashLink
                  to="/#ourvalue"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  onClick={() => setOpenDrawer(false)}
                >
                  <Typography
                    sx={{ fontSize: "14px", color: "#fff", fontWeight: 400, mt:2}}
                  >
                    Our Value
                  </Typography>
                </HashLink>
                <HashLink
                  to="/#news"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  onClick={() => setOpenDrawer(false)}
                >
                  <Typography
                    sx={{ fontSize: "14px", color: "#fff", fontWeight: 400, mt:2 }}
                  >
                    News Update
                  </Typography>
                </HashLink>
                <Link to="/contact" onClick={() => setOpenDrawer(false)}>
                  <Typography
                    sx={{ fontSize: "14px", color: "#fff", fontWeight: 400, mt:2 }}
                  >
                    Contact Us
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default CategoryMenu;

const ListLoader = () => {
  return (
    <>
      <Skeleton variant="square" sx={{width:'30%', mb:1}} />
      <Skeleton variant="square" sx={{width:'70%', mb:1}} />
      <Skeleton variant="square" sx={{width:'50%', mb:1}} />
    </>
  );
};
