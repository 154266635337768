/* global FB */

import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Common/Navbar";
import Footer from "../../Components/Common/Footer";
import {
  Box,
  Typography,
  TextField,
  InputLabel,
  Checkbox,
  Button,
  Divider,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Newsletter from "../../Components/Common/Newsletter";
import { Link, useLocation, useNavigate } from "react-router-dom";
import googleImg from "../../assets/Icons/googs.svg";
import facebook from "../../assets/Icons/Facebook.svg";
import apple from "../../assets/Icons/bi_apple.svg";
import { useSelector, useDispatch } from "react-redux";
import { setUser, setUserEmail, setUserId } from "../../store/userSlice";
import FacebookLogin from "@greatsumini/react-facebook-login";
import {
  LOGIN_MUTATION,
  RESEND_OTP,
  SIGNIN_SOCIAL,
} from "../../Utils/Mutations";
import { useMutation } from "@apollo/client";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import Loader from "../../Components/Common/Loader";
import "./login.css";
import { useGoogleLogin } from "@react-oauth/google";
import { useSnackbar } from "notistack";
import Modal from "@mui/material/Modal";
import not from "../../assets/no.png";

const style = {
  position: "absolute",
  top: "20%",
  left: { lg: "70%", md: "65%", sm: "50%", xs: "50%" },
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "transparent",

  p: 4,
};

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // =========ALERT FUNCTIONS =============================================================================================================
  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (variant, message) => () => {
    enqueueSnackbar(message, { variant });
  };
  // ================================================

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = React.useState(false);

  const [accessToken, setAccessToken] = useState(null);

  const isInFlorida = JSON.parse(localStorage.getItem("isInFlorida"));

  const dispatch = useDispatch();

  const locationState = location.state;

    const from = location.state?.from?.pathname || "/";

  // =========MUTATIONS===============================================================================================================
  const [loginMutation, { loading, error }] = useMutation(LOGIN_MUTATION);
  const [googleAuth, { loading: googleLoader }] = useMutation(SIGNIN_SOCIAL);
  const [
    resendOtpMutation,
    { loading: resendLoading, error: resendError },
  ] = useMutation(RESEND_OTP);

  // =======================================

  // =======HANDLE GOOGLE LOGIN ======================================================================================================
  const loginGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setAccessToken(tokenResponse?.access_token);
    },
  });

  useEffect(() => {
    if (accessToken) {
      handleGoogleSignIn();
    }
  }, [accessToken]);

  const handleGoogleSignIn = async () => {
    try {
      const { data } = await googleAuth({
        variables: {
          accessToken: accessToken || "",
          socialAuthProviderKey: "GOOGLE_AUTH",
          useAccessToken: true,
        },
      });

      if (data?.loginThroughSocialAuth?.message === "Login succeeded") {
        dispatch(setUser(data?.loginThroughSocialAuth));
        localStorage.setItem("isAuthenticated", "true");
        localStorage.setItem(
          "mamaketUserToken",
          data?.loginThroughSocialAuth?.accessToken
        );
        localStorage.setItem(
          "tokenExpiresIn",
          data?.loginThroughSocialAuth?.tokenExpiresIn
        );
        navigate(from, { replace: true });
        if (locationState && locationState.redirectTo) {
          navigate(`${locationState?.redirectTo}`);
        } else {
          navigate("/");
        }
      } else if (data?.loginThroughSocialAuth.message === "VERIFY_OTP") {
        navigate("/verify");
        dispatch(setUserEmail(email));
        ResendOtp();
      } else if (
        data?.loginThroughSocialAuth.message === "CREATE_TRANSACTION_PIN"
      ) {
        dispatch(setUserId(data?.loginThroughSocialAuth?.data));
        navigate("/createpin");
      }
    } catch (error) {
      handleClickVariant(
        "error",
        "Your account is not yet registered. Please sign up instead."
      );
      navigate("/register");
      console.error(error);
    }
  };

  // ======= HANDLE EMAIL & PASSWORD LOGIN ========================================================================================================

  const handleLogin = async () => {
    try {
      const { data } = await loginMutation({
        variables: { email, password },
      });

      if (data?.loginUser?.message === "Login succeeded") {
        dispatch(setUser(data?.loginUser));
        localStorage.setItem("isAuthenticated", "true");
        localStorage.setItem("mamaketUserToken", data?.loginUser?.accessToken);
        localStorage.setItem("tokenExpiresIn", data?.loginUser?.tokenExpiresIn);
        navigate(from, { replace: true });
        if (locationState && locationState.redirectTo) {
          navigate(`${locationState?.redirectTo}`);
        } else {
          navigate("/");
        }
      } else if (data?.loginUser.message === "VERIFY_OTP") {
        navigate("/verify");
        dispatch(setUserEmail(email));
        ResendOtp();
      } else if (data?.loginUser.message === "CREATE_TRANSACTION_PIN") {
        dispatch(setUserEmail(email));
        dispatch(setUserId(data?.loginUser?.data));
        navigate("/createpin");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const ResendOtp = async () => {
    try {
      const { data } = await resendOtpMutation({
        variables: { email: email },
      });

      dispatch(setUserId(data?.resendOTP?.uuid));
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  useEffect(() => {
    if (isInFlorida === null) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (isInFlorida === false) {
      handleOpen();
    }
  }, [isInFlorida]);

  return (
    <>
      <Navbar />
      {resendLoading && <Loader />}
      <Box sx={{ bgcolor: "#F8F7F7", pt: { lg: 21, xs: 15 }, pb: 5 }}>
        <Box
          sx={{
            margin: "0 auto",
            width: { lg: "30%", xs: "80%" },
            bgcolor: "#fff",
            border: "1px solid #E6E6E6",
            borderRadius: "5px",
            px: 5,
            py: 7,
            mb: 4,
          }}
        >
          <Typography
            sx={{ color: "#1a1a1a", fontWeight: 600, fontSize: "20px" }}
          >
            Welcome back
          </Typography>
          <Typography sx={{ color: "#666", fontWeight: 400, fontSize: "14px" }}>
            Please enter your detail
          </Typography>

          <Box sx={{ mt: 3 }}>
            <InputLabel
              sx={{
                color: "#2B2B2B",
                fontSize: "13px",
                fontWeight: 600,
              }}
            >
              Email
            </InputLabel>
            <TextField
              onKeyPress={handleKeyPress}
              placeholder="Email Address"
              size="large"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                style: {
                  marginTop: "10px",
                  marginBottom: "20px",
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  // Replace with your desired font family
                },
              }}
            />
            <InputLabel
              sx={{
                color: "#2B2B2B",
                fontSize: "13px",
                fontWeight: 600,
              }}
            >
              Password
            </InputLabel>
            <TextField
              value={password}
              onKeyPress={handleKeyPress}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter Password"
              type={showPassword ? "text" : "password"}
              size="large"
              fullWidth
              InputProps={{
                style: {
                  marginTop: "10px",
                  marginBottom: "20px",
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  // Replace with your desired font family
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon sx={{ fontSize: "16px" }} />
                      ) : (
                        <VisibilityOffOutlinedIcon sx={{ fontSize: "16px" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox />{" "}
                <Typography sx={{ fontSize: "14px" }}>Remember me</Typography>
              </Box>
              <Link
                to="/forget_password"
                style={{
                  textDecoration: "none",

                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#333",
                }}
              >
                Forgot password?
              </Link>
            </Box>

            {error && (
              <Typography
                sx={{
                  color: "red",
                  textAlign: "center",
                  bgcolor: "#ff00001a",
                  border: "0.5px solid red",
                  borderRadius: 1,

                  fontSize: "12px",
                }}
              >
                {error.message}
              </Typography>
            )}
            {!loading && (
              <Button
                disabled={!isInFlorida}
                variant="contained"
                fullWidth
                onClick={handleLogin}
                sx={{ borderRadius: "20px", py: 1, mt: 3 }}
              >
                Sign In
              </Button>
            )}

            {loading && (
              <>
                <LoadingButton
                  fullWidth
                  loading
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  disabled
                  sx={{ borderRadius: "20px", py: 1, mt: 3 }}
                >
                  Signing In
                </LoadingButton>
              </>
            )}
            <Typography
              sx={{
                textAlign: "center",
                mt: 1,
                fontSize: "14px",
                color: "#666",
              }}
            >
              New to Mamaket?{" "}
              <Link
                to="/register"
                style={{
                  textDecoration: "none",

                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#2E1834",
                }}
              >
                Sign up
              </Link>
            </Typography>

            <Divider sx={{ mt: 3 }}>
              <Typography>OR</Typography>
            </Divider>
          </Box>

          <Box align="center" sx={{ mt: 3 }}>
            {googleLoader && (
              <LoadingButton
                // onClick={loginGoogle}
                disabled
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                sx={{
                  bgcolor: "#F8F7F7",
                  color: "#1a1a1a",

                  fontWeight: 400,
                  fontSize: "14px",
                  py: 2,
                  borderRadius: "50px",
                }}
                fullWidth
              >
                {" "}
                <img src={googleImg} style={{ marginRight: "10px" }} />
                Signing in with Google
              </LoadingButton>
            )}
            {!googleLoader && (
              <Button
                disabled={!isInFlorida}
                onClick={loginGoogle}
                sx={{
                  bgcolor: "#F8F7F7",
                  color: "#1a1a1a",

                  fontWeight: 400,
                  fontSize: "14px",
                  py: 2,
                  borderRadius: "50px",
                }}
                fullWidth
              >
                {" "}
                <img src={googleImg} style={{ marginRight: "10px" }} />
                Sign in with Google
              </Button>
            )}

            {/* <FacebookLogin
              appId="3055807154569835"
              onSuccess={(response) => {
                 console.log("Login Success:", response);
              }}
              onFail={(error) => {}}
              onProfileSuccess={(response) => {}}
              render={({ onClick, logout }) => (
                <Button
                  onClick={onClick}
                  disabled={!isInFlorida}
                  onLogoutClick={logout}
                  sx={{
                    bgcolor: "#F8F7F7",
                    color: "#1a1a1a",

                    fontWeight: 400,
                    mt: 3,
                    fontSize: "14px",
                    py: 2,
                    borderRadius: "50px",
                  }}
                  fullWidth
                >
                  {" "}
                  <img src={facebook} style={{ marginRight: "10px" }} />
                  Sign in with Facebook
                </Button>
              )}
            /> */}

            {/* <FacebookLoginComponent/> */}
          </Box>
        </Box>
      </Box>
      <Newsletter />
      <Footer />

      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                boxShadow: 24,
                bgcolor: "#f4f4f4",
                height: "130px",
                width: "100%",
                p: 2,
                boxSizing: "border-box",
              }}
            >
              <Typography
                sx={{ fontWeight: 700, fontSize: "12px", color: "#333" }}
              >
                Sorry, this service isn't available...{" "}
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 400,
                  lineHeight: "15px",
                  textAlign: "center",
                }}
              >
                You can't purchase any item as Mamaket services are not in your
                city
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 400,
                  lineHeight: "15px",
                  mt: 1,
                  color: "#333",
                }}
              >
                Do you want Mamaket services in your city?{" "}
                <Link
                  to="/contact"
                  style={{
                    color: "#2E1834",
                    fontWeight: 600,
                    textDecoration: "underline",
                  }}
                >
                  click to send us a message
                </Link>
              </Typography>
            </Box>
            <img src={not} width={150} />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Login;







const FacebookLoginComponent = () => {
  const [isFBReady, setFBReady] = useState(false); // Track SDK initialization

  // useEffect(() => {
  //   window.fbAsyncInit = function () {
  //     FB.init({
  //       appId: '3055807154569835', // Replace with your App ID
  //       cookie: true, // Enables cookies for session management
  //       xfbml: true, // Parses social plugins on the page
  //       version: 'v21.0', // Use the correct Graph API version
  //     });

  //     console.log('Facebook SDK Initialized');
  //     setFBReady(true); // SDK is ready
  //   };

  //   // Load the SDK if it hasn’t been loaded already
  //   if (!document.getElementById('facebook-jssdk')) {
  //     const script = document.createElement('script');
  //     script.id = 'facebook-jssdk';
  //     script.src = 'https://connect.facebook.net/en_US/sdk.js';
  //     document.body.appendChild(script);
  //   }
  // }, []);

    useEffect(() => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: '3055807154569835', // Replace with your Facebook App ID
        autoLogAppEvents: true,
        xfbml: true,
          cookie     : true,
        version: 'v17.0', // Use the latest version
      });
        FB.AppEvents.logPageView(); 
    };
    (function (d, s, id) {
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    const js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
  }, []);

 const handleFacebookLogin = () => {
    window.FB.login((response) => {
      if (response.authResponse) {
        console.log('Logged in:', response);
        // Fetch user information
        window.FB.api('/me', { fields: 'name,email,picture' }, (userInfo) => {
          console.log(userInfo);
        });
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, { scope: 'email' });
  };

  return (
    // <button onClick={handleFacebookLogin} style={{ padding: '10px', fontSize: '16px' }}>
    //   Login with Facebook
    // </button>
                    <Button
                onClick={handleFacebookLogin}
                  sx={{
                    bgcolor: "#F8F7F7",
                    color: "#1a1a1a",

                    fontWeight: 400,
                    mt: 3,
                    fontSize: "14px",
                    py: 2,
                    borderRadius: "50px",
                  }}
                  fullWidth
                >
                  {" "}
                  <img src={facebook} style={{ marginRight: "10px" }} />
                  Sign in with Facebook
                </Button>
  );
};





