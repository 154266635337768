import { Avatar, Box, IconButton, Typography, Rating, InputLabel } from "@mui/material";
import React from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import img1 from "../../assets/Products/Web Images.png";
import japan from "../../assets/Japan.svg";
import CurrencyDisplay from "../Common/CurrencyDisplay";

const ServiceCard = ({ image, title, price, location, description }) => {
  return (
    <>
      <Box
        sx={{
          border: "1px solid #F8F7F7",
          bgcolor: "#fff",
          borderRadius: "8px",
          cursor: "pointer",
          transition: "0.3s all linear",
          p: 1,
          overflow:'hidden',
          
          "&:hover": {
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          },
        }}
      >
        <Box align="right" sx={{mt:-1, mr:-1}}>

        <Typography sx={{bgcolor:'primary.main', color:'#fff', width:'fit-content', px:0.5, fontSize:'8px',}}>Service</Typography>
        </Box>
        <Box>
     
                    <Box
            
     
            sx={{ width: "100%", height: 
            {xs:"120px", sm:'200px', md:'200px', 
            lg:'150px'} , 
          backgroundImage:`url('${image }')`,
          backgroundSize:'100%',
          backgroundRepeat:'no-repeat',
          backgroundPosition:'center',
borderRadius:'5px',
// border:'0.2px solid #dedede',
backgroundColor:'#f4f4f4'
          }}
          />
          
          <Box sx={{ mt: 2 , height:'70px'}}>
            <InputLabel
            
              sx={{ fontSize: {lg:"14px", md:'14px', sm:'12px', xs:'12px'}, fontWeight: 600,color:'#1a1a1a'  }}
            >
              {title}
            </InputLabel>
                   <Typography
                          variant="caption"
                          className="two-line-ellipsis"
                          sx={{ fontSize: "8px", fontWeight: 300, color:'#1a1a1a', height:'26px' }}
                        >
                          {description}
                        </Typography>
  
            <Typography
              variant="caption"
              sx={{ color: "#1a1a1a", fontWeight: 500, fontSize: "14px" , mt:1}}
            >
              <span style={{fontSize:'10px',  marginRight:'1px'}}>&#36;</span>{price.toLocaleString()}
            </Typography>
            <Box>
        
            </Box>
  
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ServiceCard;
