import React, { useEffect } from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../store/productSlice";
import Loader from "../Common/Loader";
import StatusCode from "../../Utils/StatusCode";
import ProductsCard from "../Cards/ProductsCard";
import { da } from "date-fns/locale";
import { LuMoveRight } from "react-icons/lu";

const LatestProducts = () => {
  const { data, status } = useSelector((state) => state.products);
  const theme = useTheme();
  const isMobille = useMediaQuery(theme.breakpoints.down("md"));


  return (
    <>
      {status === StatusCode.LOADING && <Loader />}
      <Box
        sx={{
          p: 2,
          pb: 4,
          my: 3,
          mx: 0,
          bgcolor: " #f6f7f8",
          boxShadow:
            " rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
          borderRadius: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: {
                lg: "24px",
                xs: "16px",
                md: "24px",
                sm: "18px",
              },
              fontWeight: 700,
            }}
          >
            Our Latest Products
          </Typography>
          <Link to="/latest-products">
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: {
                  lg: "14px",
                  xs: "12px",
                  md: "14px",
                  sm: "12px",
                },
                fontWeight: 400,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                columnGap: 2,
                "&:hover": {
                  textDecoration: "underline",
                  fontWeight: 600,
                },
              }}
            >
              See more <LuMoveRight />
            </Typography>
          </Link>
        </Box>
        <Box>
          {isMobille ? (
            <>
              <Grid container spacing={1} sx={{ mt: 3 }}>
                {data === undefined ? (
                  <p>No Product</p>
                ) : (
                  data.slice(0, 10).map((product, index) => (
                    <Grid
                      item
                      sm={6}
                      xs={6}
            
                      key={product._id}
                    >
                      <Link to={`/product-view/${product._id}`} key={index}>
                        <ProductsCard
                          image={product.images && product.images[0]}
                          price={product.price.toLocaleString()}
                          flag={product?.country?.flagUrl}
                          title={product?.name}
                          ratings={product?.rating}
                                     description={product?.description}
                        />
                      </Link>
                    </Grid>
                  ))
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                {data === undefined ? (
                  <p>No Data</p>
                ) : (
                  data.slice(0, 10).map((product, index) => (
                    <Grid item lg={2.4} md={3} sm={4} xs={6} key={product._id}>
                      <Link to={`/product-view/${product._id}`} key={index}>
                        <ProductsCard
                          image={product.images && product.images[0]}
                          price={product.price.toLocaleString()}
                          flag={product?.country?.flagUrl}
                          title={product?.name}
                                       ratings={product?.rating}
                                       description={product?.description}
                        />
                      </Link>
                    </Grid>
                  ))
                )}
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default LatestProducts;
