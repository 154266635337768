import React, { useEffect, useState } from "react";
import {
  Box,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FiSearch } from "react-icons/fi";
import { useQuery } from "@apollo/client";
import { GET_PRODUCT_ORIGINS } from "../../Utils/Queries";
import { useNavigate } from "react-router-dom";

const ProductOrigins = () => {
  const { data, loading, error } = useQuery(GET_PRODUCT_ORIGINS);

  const productOrigins = data?.searchProductOrigins?.items || [];



  const [filteredOrigins, setFilteredOrigins] = useState([]);
  const [searchParams, setSearchParams] = useState("");

  const [isMobileSearch, setIsMobileSearch] = useState(false);

  useEffect(() => {
    if (productOrigins) {
      setFilteredOrigins(productOrigins);
    }
  }, [productOrigins]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchParams(value);

    const filtered = productOrigins.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredOrigins(filtered);
  };

  const [sortedOrigins, setSortedOrigins] = useState([])
 useEffect(() => {
    if (filteredOrigins) {
      const sortedProducts = [...filteredOrigins].sort((a, b) => {
        if (b.productCount > 0 && a.productCount === 0) return 1;
        if (a.productCount > 0 && b.productCount === 0) return -1;
        if (a.productCount > 0 && b.productCount > 0) return b.productCount - a.productCount;
        return 0;
      });

      setSortedOrigins(sortedProducts);
    }
  }, [filteredOrigins]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();

  const handleNavigate = (name, id) =>{

    navigate(`/product-origins/${name}/${id}`)
  }

  return (
    <>
      <Box
        sx={{
          p: 2,
          pb: 4,
          my: 3,
          mx: 0,
          bgcolor: " #f6f7f8",
          boxShadow:
            " rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
          borderRadius: "5px",
        }}
      >
        <Box>
          {isMobileSearch ? (
            <>
            <Box>
                      <TextField
                      onChange={handleSearch}
                      value={searchParams}
                      size="small"
                      placeholder="Search Country"
                      fullWidth
                      InputProps={{
                        style: {
                          borderRadius: "8px",
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <FiSearch />{" "}
                          </InputAdornment>
                        ),
                      }}
                    />
            </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: {
                      lg: "24px",
                      xs: "16px",
                      md: "24px",
                      sm: "18px",
                    },
                    fontWeight: 700,
                  }}
                >
                  Our Product Origin
                </Typography>
                <Box sx={{ width: isMobile ? "auto" : "30%" }}>
                  {isMobile ? (
                    <Typography onClick={() => setIsMobileSearch(true)}>
                      {" "}
                      Search...{" "}
                    </Typography>
                  ) : (
                    <TextField
                      onChange={handleSearch}
                      value={searchParams}
                      placeholder="Search Country"
                      fullWidth
                      InputProps={{
                        style: {
                          borderRadius: "8px",
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <FiSearch />{" "}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Box>
              </Box>
            </>
          )}

          {!filteredOrigins || filteredOrigins?.length === 0 ? (
            <>
              <Box sx={{ my: 2 }}>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#333",
                    fontWeight: 500,
                    fontSize: "16px",
                  }}
                >
                  <b>{searchParams ? searchParams.toUpperCase() : "Product origin"}</b> is not currently available
                </Typography>
              </Box>
            </>
          ) : (
            <div className="origins">
              <div className="origins-slide">
                {sortedOrigins &&
                  sortedOrigins?.map((origin, index) => (
                    <div className="origins-box" key={index} onClick={()=>handleNavigate(origin.name, origin?._id)}>
                      <img src={origin?.flagUrl} />
                      
                                  <InputLabel sx={{ color: "#333" ,}}>
                 {origin?.name}
                      </InputLabel><Typography>({origin?.productCount})</Typography>
                    </div>
                  ))}
              </div>
              <div className="origins-slide">
                {sortedOrigins &&
                  sortedOrigins?.map((origin, index) => (
                    <div className="origins-box" key={index} onClick={()=>handleNavigate(origin.name, origin?._id)}>
                      <img src={origin?.flagUrl} />
            
                  <InputLabel sx={{ color: "#333" ,}}>
                 {origin?.name}
                      </InputLabel><Typography>({origin?.productCount})</Typography>
       
                    </div>
                  ))}
              </div>
            </div>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ProductOrigins;
