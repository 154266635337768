import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Common/Navbar";
import Newsletter from "../../Components/Common/Newsletter";
import Footer from "../../Components/Common/Footer";
import { Box, Button, Typography, Divider } from "@mui/material";
import google from "../../assets/Icons/googs.svg";
import facebook from "../../assets/Icons/Facebook.svg";
import apple from "../../assets/Icons/bi_apple.svg";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { useMutation, useLazyQuery } from "@apollo/client";
import { SIGNUP_SOCIAL } from "../../Utils/Mutations";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch } from "react-redux";
import { setUserId } from "../../store/userSlice";
import { GET_COUNTRIES } from "../../Utils/Queries";

const RegisterMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [accessToken, setAccessToken] = useState(null);

  const handleClickVariant = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const [getCountries, { loading, error, data: countryData }] = useLazyQuery(
    GET_COUNTRIES
  );

  const loginGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {


      setAccessToken(tokenResponse?.access_token);

    },
  });
  const [googleAuth, { loading: googleLoader }] = useMutation(SIGNUP_SOCIAL);

  const handleGoogleAuth = async () => {
    try {
      const { data } = await googleAuth({
        variables: {
          accessToken: accessToken,
          socialAuthProviderKey: "GOOGLE_AUTH",
          useAccessToken: true,
          country: countryData?.getAllCountries?.items[0]?._id,
        },
      });

      dispatch(setUserId(data?.registerThroughSocialAuth?.userId));
      navigate("/createpin");
    } catch (err) {

      handleClickVariant("error", `${err.message}`);
      navigate("/login");
    }
  };

  useEffect(() => {
    if (accessToken) {
      handleGoogleAuth();
    }
  }, [accessToken]);

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <>
      <Navbar />
      <Box sx={{ bgcolor: "#F8F7F7", pt: { lg: 21, xs: 15 }, pb: 5 }}>
        <Box
          sx={{
            margin: "0 auto",
            width: { lg: "30%", xs: "80%" },
            bgcolor: "#fff",
            border: "1px solid #E6E6E6",
            borderRadius: "5px",
            px: 5,
            py: 7,
            mb: 4,
          }}
        >
          <Typography
            sx={{ color: "#1a1a1a", fontWeight: 600, fontSize: "20px" }}
          >
            Welcome
          </Typography>
          <Typography sx={{ color: "#666", fontWeight: 400, fontSize: "14px" }}>
            Please enter your detail
          </Typography>
          <Link to="/register-form/">
            <Button
              variant="contained"
              fullWidth
              size="large"
              sx={{ borderRadius: "100px", mt: 3 }}
            >
              Sign Up with email
            </Button>
          </Link>
          <Divider sx={{ mt: 3 }}>
            <Typography>OR</Typography>
          </Divider>

          <Box sx={{ mt: 3 }}>
            {googleLoader && (
              <LoadingButton
                disabled
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                sx={{
                  bgcolor: "#F8F7F7",
                  color: "#1a1a1a",
        
                  fontWeight: 400,
                  fontSize: "14px",
                  py: 2,
                  borderRadius: "50px",
                }}
                fullWidth
              >
                {" "}
                <img src={google} style={{ marginRight: "10px" }} />
                Signing Up with Google
              </LoadingButton>
            )}
            {!googleLoader && (
              <Button
                onClick={loginGoogle}
                sx={{
                  bgcolor: "#F8F7F7",
                  color: "#1a1a1a",
        
                  fontWeight: 400,
                  fontSize: "14px",
                  py: 2,
                  borderRadius: "50px",
                }}
                fullWidth
              >
                {" "}
                <img src={google} style={{ marginRight: "10px" }} />
                Sign Up with Google
              </Button>
            )}
            {/* <Button
              sx={{
                bgcolor: "#F8F7F7",
                color: "#1a1a1a",
      
                fontWeight: 400,
                mt: 3,
                fontSize: "14px",
                py: 2,
                borderRadius: "50px",
              }}
              fullWidth
            >
              {" "}
              <img src={apple} style={{ marginRight: "10px" }} />
              Sign in with Apple ID
            </Button> */}
            {/* <Button
              sx={{
                bgcolor: "#F8F7F7",
                color: "#1a1a1a",
      
                fontWeight: 400,
                mt: 3,
                fontSize: "14px",
                py: 2,
                borderRadius: "50px",
              }}
              fullWidth
            >
              {" "}
              <img src={facebook} style={{ marginRight: "10px" }} />
              Sign in with Facebook
            </Button> */}
          </Box>
        </Box>
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
};

export default RegisterMain;
